import { memo } from 'react'
import Marquee from 'react-fast-marquee'
import { shuffle } from '../../helper'
import ProgramIcon from '../../icons/program-icons'

const marqueLogos = shuffle([
  // <ProgramIcon key="Stayhard" programName="Stayhard" />,
  // <ProgramIcon key="Lindex" programName="Lindex" />,
  // <ProgramIcon key="Åhlens" programName="Åhlens" />,
  <ProgramIcon key="Nelly" programName="Nelly" />,
  <ProgramIcon key="NA-KD" programName="NA-KD" />,
  <ProgramIcon key="Nike" programName="Nike" />,
  <ProgramIcon key="Homeroom" programName="Homeroom" />,
  <ProgramIcon key="Cervera" programName="Cervera" />,
  <ProgramIcon key="Designtorget" programName="Designtorget" />,
  // <ProgramIcon key="Media Markt" programName="Media Markt" />,
  <ProgramIcon key="Grandpa" programName="Grandpa" />,
  <ProgramIcon key="Hifiklubben" programName="Hifiklubben" />,
  <ProgramIcon key="Apotek Hjärtat" programName="Apotek Hjärtat" />,
  // <ProgramIcon key="Indiska" programName="Indiska" />,
  <ProgramIcon key="Bubbleroom" programName="Bubbleroom" />,
  // <ProgramIcon key="BGA" programName="BGA" />,
  <ProgramIcon key="Polarn O. Pyret" programName="Polarn O. Pyret" />,
  <ProgramIcon key="Guldfynd" programName="Guldfynd" />,
  <ProgramIcon key="Litenleker" programName="Litenleker" />,
  <ProgramIcon key="Elon" programName="Elon" />,
  <ProgramIcon key="Cellbes" programName="Cellbes" />,
  <ProgramIcon key="Gymgrossisten" programName="Gymgrossisten" />,
  <ProgramIcon key="Prada" programName="Prada" />,
  <ProgramIcon key="Louis Vuitton" programName="Louisvuitton" />,
])

const ProgramIconsMarquee = () => (
  <div id="marquee-wrapper">
    {/* <p>I samarbete med 350 butiker</p> */}
    <Marquee gradient={false} speed={20}>
      { /* eslint-disable-next-line react/no-array-index-key */ }
      {marqueLogos.map((icon, i) => <div key={`logo-${i}`}>{icon}</div>)}
    </Marquee>
  </div>
)

export default memo(ProgramIconsMarquee)
